<template>
    <header>
        <div
            v-if="practice && practice.hasReapplicationDue"
            class="w-full py-3 px-4 mt-2 bg-red-light rounded flex justify-between items-center"
        >
            <div class="text-sm text-red-dark">
                <fa-icon icon="exclamation-triangle" class="text-red mr-1"></fa-icon>
                This practice is due for an accreditation renewal. If you would like to renew accreditation please click 'Renewal Due' on the right.
            </div>
            <el-button
                type="danger"
                size="mini"
                class="font-bold rounded uppercase align-middle text-xs text-white"
                @click="renewalClickHandler(practice)"
            >
                Renewal Due
                <fa-icon icon="chevron-right" class="text-xs"></fa-icon>
            </el-button>
        </div>

        <div
            v-if="practice && practice.hasReapplicationPaymentDue"
            class="w-full py-3 px-4 mt-2 bg-yellow-light rounded flex justify-between items-center"
        >
            <div class="text-sm text-yellow-dark">
                <fa-icon icon="exclamation-triangle" class="text-yellow mr-1"></fa-icon>
                This practice's accreditation renewal payment is due. Please click 'Renewal Payment Due' on the right.
            </div>
            <el-button
                type="warning"
                size="mini"
                class="font-bold rounded uppercase align-middle text-xs text-white"
                @click="renewalPaymentClickHandler(practice)"
            >
                Renewal Payment Due
                <fa-icon icon="chevron-right" class="text-xs"></fa-icon>
            </el-button>
        </div>


        <div
            v-if="practice && practice.preferenceDaysDueVisit"
            class="w-full py-3 px-4 mt-2 bg-red-light rounded flex justify-between items-center"
        >
          <div class="text-sm text-red-dark">
            <fa-icon icon="exclamation-triangle" class="text-red mr-1"></fa-icon>
            This practice's survey visit is due in {{practice.preferenceDaysDueVisitDueMonth}}. Click 'Set Availability' on the right to select preferred days of the week for your booking.
          </div>
          <el-button
              type="danger"
              size="mini"
              class="font-bold rounded uppercase align-middle text-xs text-white"
              @click="preferredDaysDueClickHandler(practice.preferenceDaysDueVisit.id)"
          >
            Set Availability
            <fa-icon icon="chevron-right" class="text-xs"></fa-icon>
          </el-button>
        </div>

<!--        <div-->
<!--            v-if="practice && practice.hasSurveyConfirmationDue"-->
<!--            class="w-full py-3 px-4 mt-2 bg-yellow-light rounded flex justify-between items-center"-->
<!--        >-->
<!--            <div class="text-sm text-yellow-dark">-->
<!--                <fa-icon icon="exclamation-triangle" class="text-yellow mr-1"></fa-icon>-->
<!--                This practice's survey visit confirmation is due. Please click 'Survey Confirmation Due' on the right.-->
<!--            </div>-->
<!--            <el-button-->
<!--                type="warning"-->
<!--                size="mini"-->
<!--                class="font-bold rounded uppercase align-middle text-xs text-white"-->
<!--                @click="surveyConfirmationDueClickHandler(practice)"-->
<!--            >-->
<!--                Survey Confirmation Due-->
<!--                <fa-icon icon="chevron-right" class="text-xs"></fa-icon>-->
<!--            </el-button>-->
<!--        </div>-->

        <div class="flex flex-row pt-3 pb-8" v-if="practice">
            <div class="header_main flex flex-col xl:flex-row justify-between flex-grow mr-3">
                <div class="flex flex-col mb-4">
                    <div class="flex flex-row items-center">
                        <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="practiceName">{{ practiceName }}</h1>

                    </div>
                    <div class="flex flex-col lg:flex-row flex-grow">
                        <div class="flex flex-row mb-1 lg:mb-0">
                            <div class="text-grey-2 text-sm mr-5 whitespace-no-wrap">
                                <fa-icon icon="map-marker-alt" class="mr-1 text-grey-2"/>
                                {{ practiceLocation }}
                            </div>
                            <div class="text-grey-2 text-sm whitespace-no-wrap mr-5 ">
                                {{ practiceType }}
                                <a :href="website" target="_blank" v-if="website">
                                    <fa-icon icon="external-link-alt" class="ml-4 text-primary-light"/>
                                </a>
                            </div>
                        </div>
                        <div class="text-grey-2 text-sm mr-5 flex flex-row leading-4">
                            <fa-icon icon="phone-alt" class="mr-1 text-grey-2" v-if="userPhoneNumber || userName"/>
                            <span class="mr-5" v-if="userName">{{ userName }}</span>
                            <span class="ml-2" v-if="userPhoneNumber">{{ userPhoneNumber }}</span>
                            <router-link
                                data-cy="editPractice"
                                class="group uppercase text-primary text-sm text-primary-light hover:text-primary"
                                :to="{name: 'edit-practice', params: {id: getPracticeId}}"
                            >
                                <fa-icon icon="pencil-alt" class="mr-1 group-hover:text-primary-light"/>
                                <span class="font-bold group-hover:text-primary-light">{{editButtonText}}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row flex-grow-0 lg:flex-grow items-start xl:items-center justify-start xl:justify-center">
                    <div class="flex flex-row xl:flex-col mr-4" v-if="svScheduledDate">
                        <div class="text-grey-2 mr-2 text-sm uppercase">SV Date</div>
                        <div class="text-grey-2 text-sm">{{ svScheduledDate }}</div>
                    </div>
                    <div class="flex flex-row xl:flex-col mr-4" v-if="expiryDate">
                        <div class="text-grey-2 mr-2 text-sm uppercase">Expiry</div>
                        <div class="text-sm" :class="getExpiryTextColor()">{{ expiryDate }} <span
                            v-if="isExpiring">(<span class="text-red">{{expiringMonthsText}}</span>)</span>
                        </div>
                    </div>
                    <div class="flex flex-row xl:flex-col mr-4" v-if="extDate">
                        <div class="text-grey-2 mr-2 text-sm uppercase">Ext. Date</div>
                        <div class="text-grey-2 text-sm">{{ extDate }}</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row items-start xl:items-center mt-2 lg:mt-0">
                <div class="mr-4">
                    <div class="text-sm uppercase text-right text-grey-2 whitespace-no-wrap">Quality Accreditation Manager</div>
                    <div class="text-sm text-right text-grey-2" v-if="agentName">{{ agentName }}</div>
                    <div class="text-sm text-right text-grey-2" v-else>Not assigned</div>
                </div>
                <ProfileAvatar :user-profile="qam" class="w-12 h-12" />
            </div>
        </div>
        <div class="flex items-center justify-center h-20" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </header>
</template>

<script>
import ProfileAvatar from '@/components/partials/ProfileAvatar'
import { mapState } from 'vuex'
import Loader from '@/assets/loader.svg'
import { format, isValid, parseISO, addMonths, parse, isAfter, differenceInMonths } from 'date-fns'
import practiceHelpers from '@/components/shared/mixins/practiceHelpers'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import RenewalHelpers from '@/components/shared/mixins/renewalHelpers'
import SurveyConfirmationDueHelpers from '@/components/shared/mixins/surveyConfirmationDueHelpers'
import PreferredDaysDueHelpers from '@/components/shared/mixins/preferredDaysDueHelpers'

export default {
    name: 'HeaderComponent',
    components: {
        Loader,
        ProfileAvatar
    },
    mixins: [practiceHelpers, HttpMixin, MessageDialog, BackendHelpers, RenewalHelpers, SurveyConfirmationDueHelpers,
      PreferredDaysDueHelpers],
    props: {
        practice: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            numCertificateExpiryMonths: 18,
            defaultContact: {
                firstName: '',
                lastName: '',
                phone: ''
            },
            practiceTypes: [
                {
                    value: 'MDS',
                    label: 'Medical Deputising Service'
                },
                {
                    value: 'GP',
                    label: 'General Practice'
                }
            ]
        }
    },
    methods: {
        getExpiryTextColor() {
            return this.isExpiring ? 'text-red' : 'text-grey-2'
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        practiceName() {
            return this.practice && this.practice !== null ? this.practice.name : ''
        },
        practiceType() {
            if (this.practice) {
                const practiceTypes = this.staticChoices['practice.practice_type']
                const practiceType = practiceTypes ? practiceTypes.find(pt => pt.value === this.practice.practiceType) : ''
                return practiceType !== null && practiceType !== undefined ? practiceType.label : ''
            }
            return ''
        },
        organisationPtr() {
            return this.practice !== null && this.practice !== undefined ? this.practice.organisationPtr : {}
        },
        practiceLocation() {
            if (this.organisationPtr !== null && this.organisationPtr !== undefined) {
                let address = this.organisationPtr.physicalAddress || {}
                return address !== null ? address.suburb + ', ' + address.state : ''
            }
            return ''
        },
        accreditation() {
            if (this.practice) {
                return this.practice.accreditations != null ? this.practice.accreditations[0] : null
            }
            return null
        },
        userName() {
            if (this.accreditation) {
                let contact = this.accreditation != null ? this.accreditation.nominatedAccreditationContact : this.defaultContact
                return contact ? contact.firstName + ' ' + contact.lastName : ''
            }
            return ''
        },
        userPhoneNumber() {
            if (this.practice) {
                let contact = this.accreditation != null ? this.accreditation.nominatedAccreditationContact : this.defaultContact
                return contact && contact.phone ? contact.phone.number : ''
            }
            return ''
        },
        agentName() {
            return this.qam ? this.qam.firstName + ' ' + this.qam.lastName : ''
        },
        qam() {
            if (this.practice) {
                let qam = this.accreditation != null ? this.accreditation.qam : this.defaultContact
                return qam
            }
            return null
        },
        svScheduledDate() {
            // const finalVisits = this.accreditation && this.accreditation.visits && this.accreditation.visits.length > 0 ?
            //     this.accreditation.visits.filter(v => v.status === 'FINAL') : null
            let svDateTime = ''

            // Check exits record this.accreditation?.visits
            if (this.accreditation?.visits?.length > 0) {
                const visit = this.accreditation.visits[0]
                const svScheduledDate = parseISO(visit.date)

                // Format time to 00:00:00 and convert to time() for compare two dates
                const currentDate = new Date(format(new Date(), 'yyyy/MM/dd'))

                // Check if there are any visits with status = 'FINAL' and the date = future date 
                if (isValid(svScheduledDate) && currentDate.getTime() < svScheduledDate.getTime()){
                    svDateTime = format(svScheduledDate, 'dd/MM/yyyy')
                    const startTime = parse(visit.startTime, 'HH:mm:ss', svScheduledDate)
                    const endTime = parse(visit.endTime, 'HH:mm:ss', svScheduledDate)

                    if (isValid(startTime)) {
                        svDateTime += ' ' + format(startTime, 'h:mm a')
                        if (isValid(endTime)) {
                            svDateTime += ' - ' + format(endTime, 'h:mm a')
                        }
                    }
                }
            }
            
            // Check if there are any visits with status 'PREFERRED_DAYS_REQ' or 'DRAFT' and date_preferred_days_notif_sent is not empty
            if (svDateTime === '' && isValid(parseISO(this.accreditation?.prefAndDraftVisits?.[0]?.datePreferredDaysNotifSent))) {
                const preferredDaysVisit = this.accreditation.prefAndDraftVisits[0]
                const svDate = parseISO(preferredDaysVisit.due)
                console.log('svDate: ' + svDate)

                if (isValid(svDate))
                    svDateTime = format(svDate, 'MM/yyyy')
            }

            return svDateTime
        },
        expiryDate() {
            return this.getExpiryDate(this.accreditation)
        },
        extDate() {
            if (!this.accreditation || !this.accreditation.extDate) return ''

            const extDate = parseISO(this.accreditation.extDate)
            return isValid(extDate) ? format(extDate, 'dd/MM/yyyy') : ''
        },
        numMonthsToExpiry() {
            if (!this.expiryDate) return false

            const expiryDate = parse(this.expiryDate, 'dd/MM/yyyy', new Date())
            return differenceInMonths(expiryDate, new Date())
        },
        isExpiring() {
            return this.numMonthsToExpiry <= this.numCertificateExpiryMonths
        },
        expiringMonthsText() {
            return this.numMonthsToExpiry >= 0 ?  `${this.numMonthsToExpiry} MONTHS UNTIL EXPIRY` : `${-this.numMonthsToExpiry} MONTHS AGO`
        },
        status() {
            return this.practice != null ? this.practice.status : ''
        },
        website() {
            let website = this.practice != null && this.practice.organisationPtr != null ? this.practice.organisationPtr.website : ''
            if (website && website.indexOf('http') === -1) {
                website = 'https://' + website
            }
            return website
        },
        getPracticeId() {
            if (this.practice) {
                return this.practice.organisationPtrId
            }
            return ''
        },
        practiceStatus() {
            return this.staticChoices['practice.status'] || []
        },
        editButtonText() {
            return this.practice.hasReapplicationPaymentDue ? 'View' : 'Edit'
        }
    }
}
</script>
