<template>
    <div>
<!--    <GlobalHeader :goBack="{ name: 'PracticeList'}" />-->
    <div class="container flex flex-col">
        <Header :practice="practice" />
        <el-tabs class="main-tabs" v-model="activeTab" :stretch="true">
            <el-tab-pane name="Staff">
                <div slot="label" class="main-tabs__tab-header flex flex-row font-display text-base lg:text-xl text-black" data-cy="staff">
                    <!-- <i class="el-tabs__icon c-icon flex items-center el-icon-circle-plus-outline"></i> -->
                    Staff
                    <div
                        class="tab-count ml-3"
                        v-if="staffDisplay"
                        data-cy="staffTab"
                    >
                        <fa-icon icon="user" class="text-sm text-grey-6" />
                        <span class="text-xs text-grey-2 ml-2">{{ staffDisplay }}</span>
                    </div>
                    <div class="ml-3" v-show="!isStaffFirstView">
                        <span class="text-grey-2 block" @click="showForceStaffFirstView">
                        <fa-icon icon="question-circle" class="text-sm text-grey-6" />
                        </span>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane name="Documents">
                <div slot="label" class="main-tabs__tab-header flex items-center flex-row font-display text-base lg:text-xl text-black" data-cy="documentsTab">
                    <!-- <i class="el-tabs__icon c-icon flex items-center el-icon-circle-plus-outline"></i> -->
                    Documents
                    <div
                        v-if="docsDisplay"
                        class="ml-3 tab-count"
                        data-cy="documentsTab"
                    >
                        <fa-icon
                            icon="file-alt"
                            class="text-sm text-grey-6"
                        />
                        <span class="text-grey-2 text-xs ml-2">{{ docsDisplay }}</span>
                    </div>
                    <span v-show="!isDocumentsFirstView" class="ml-3 text-grey-2 block" @click="showForceDocumentsFirstView">
                        <fa-icon icon="question-circle" class="text-sm text-grey-6" />
                    </span>
                </div>
            </el-tab-pane>
            <el-tab-pane name="Accreditation">
                <div slot="label" class="flex flex-row font-display text-base lg:text-xl text-black" data-cy="accreditationTab">
                    <!-- <i class="el-tabs__icon c-icon flex items-center el-icon-circle-plus-outline"></i> -->
                    Accreditation
                    <div class="ml-3" v-show="!isAccreditationFirstView">
                        <span class="text-grey-2 block" @click="showForceAccreditationFirstView">
                            <fa-icon icon="question-circle" class="text-sm text-grey-6" />
                        </span>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <router-view
            :forceStaffFirstView="forceStaffFirstView"
            @cancelForceStaffFirstView="cancelForceStaffFirstView"
            :forceDocumentsFirstView="forceDocumentsFirstView"
            @cancelForceDocumentsFirstView="cancelForceDocumentsFirstView"
            @updateForceDocumentsFirstView="updateForceDocumentsFirstView"
            :forceAccreditationFirstView="forceAccreditationFirstView"
            @cancelForceAccreditationFirstView="cancelForceAccreditationFirstView"
          ></router-view>
    </div>
</div>
</template>

<script>
import GET_PRACTICE from '@/graphql/queries/getPractice.gql'
import Header from '@/components/shared/Header'
import GlobalFooter from '@/components/shared/GlobalFooter'
import GlobalHeader from '@/components/shared/GlobalHeader'
import { mapState } from 'vuex'
import camelcaseKeys from 'camelcase-keys'
import FileMethods from '@/components/shared/mixins/fileMethods'
import {format, isValid, parseISO} from "date-fns"

export default {
    name: 'MainView',
    components: {
        Header,
        GlobalFooter,
        GlobalHeader,
    },
    mixins: [FileMethods],
    props: {
        id: {
            type: [String, Number],
            default: null,
        }
    },
    data() {
        return {
            activeTab: '',
            routeName: '',
            assessmentId: '',
            indicatorSetId: '',
            indicatorId: '',
            practiceId: null,
            currentMessageDialog: null,
            forceStaffFirstView: false,
            forceDocumentsFirstView: false,
            forceAccreditationFirstView: false
        }
    },
    mounted() {
        // set the practiceId
        this.practiceId = Number(this.id)
        this.activeTab = this.$route.name === 'Assessment' ? 'Accreditation' : this.$route.name
        this.routeName = this.$route.name !== this.activeTab ? this.$route.name : ''
        this.assessmentId = this.$route.params.assessmentId ? this.$route.params.assessmentId : ''
        this.indicatorSetId = this.$route.params.indicatorSetId ? this.$route.params.indicatorSetId : ''
        this.indicatorId = this.$route.params.indicatorId ? this.$route.params.indicatorId : ''
    },
    apollo: {
        practice: {
            query: GET_PRACTICE,
            variables() {
                return {
                    id: this.practiceId
                }
            },
            skip() {
                return !this.practiceId
            },
            update(data) {
                const practice = camelcaseKeys(data.practice || {}, { deep: true })
                practice.hasReapplicationDue = practice.reapplicationsAggregate.aggregate.count > 0
                practice.hasReapplicationPaymentDue = practice.reapplicationsPaymentAggregate.aggregate.count > 0

                const accreditation = Array.isArray(practice.accreditations)
                    ? practice.accreditations[0]
                    : null
                const visit = Array.isArray(accreditation?.visits) ? accreditation?.visits[0] : null
                practice.hasSurveyConfirmationDue = visit?.confirmationFormStatus === 'SENT'

                if (accreditation) {
                  practice.preferenceDaysDueVisit = Array.isArray(accreditation?.preferredDaysVisits) ? accreditation?.preferredDaysVisits[0] : null
                } else {
                  practice.preferenceDaysDueVisit = null
                }

                if (practice.preferenceDaysDueVisit) {
                  const prefDueMonth = parseISO(practice.preferenceDaysDueVisit.due)
                  practice.preferenceDaysDueVisitDueMonth = isValid(prefDueMonth) ? format(prefDueMonth, 'MMMM yyyy') : ''
                } else
                {
                  practice.preferenceDaysDueVisitDueMonth = null
                }

                return practice
            },
            fetchPolicy: 'network-only',
        }
    },
    methods: {
        showForceStaffFirstView() {
            this.forceStaffFirstView = true
        },
        cancelForceStaffFirstView() {
            this.forceStaffFirstView = false
        },
        showForceDocumentsFirstView() {
            this.forceDocumentsFirstView = !this.forceDocumentsFirstView
        },
        updateForceDocumentsFirstView(value) {
            this.forceDocumentsFirstView = value
        },
        cancelForceDocumentsFirstView() {
            this.forceDocumentsFirstView = false
        },
        showForceAccreditationFirstView() {
            this.forceAccreditationFirstView = true
        },
        cancelForceAccreditationFirstView() {
            this.forceAccreditationFirstView = false
        },
        clearForceFirstViews() {
            if (this.activeTab !== "Staff") {
                this.forceStaffFirstView = false
            }
            if (this.activeTab !== "Documents") {
                this.forceDocumentsFirstView = false
            }
            if (this.activeTab !== "Accreditation") {
                this.forceAccreditationFirstView = false
            }
        }
    },
    computed: {
        ...mapState({
            isStaffFirstView: state => state.app.isStaffFirstView,
            isDocumentsFirstView: state => state.app.isDocumentsFirstView,
            isAccreditationFirstView: state => state.app.isAccreditationFirstView,
        }),
        staffDisplay() {
            return this.$store.state.app.staffTotal
        },
        docsDisplay() {
            return this.$store.state.app.docsTotal
        },
    },
    watch: {
        practiceId() {
            this.$store.dispatch('setPracticeId', {practiceId: this.practiceId})
        },
        activeTab() {
            let params = { id: this.practiceId.toString() }
            if (this.assessmentId) params['assessmentId'] = this.assessmentId
            if (this.indicatorSetId) params['indicatorSetId'] = this.indicatorSetId
            if (this.indicatorId) params['indicatorId'] = this.indicatorId

            let routeName = 
                this.activeTab === 'Accreditation' && this.$route.name === 'Assessment' ? 
                    'Assessment' : 
                    this.activeTab

            this.clearForceFirstViews()

            this.$router.push({
                name: routeName,
                params,
            }).catch(err => err)
        },
    },
}
</script>
